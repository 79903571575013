.page-broker-money {
    overflow: auto;
}

.pbm-monitor {
    padding: 0 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

    &-header {
        font-weight: bold;
        border-bottom: 1px solid #EAEDF3;
        height: 46px;
        line-height: 46px;
    }

    &-body {
        margin-top: 10px;
        display: flex;
        padding-bottom: 10px;
    }
    .ant-space {
        flex-wrap: wrap;
    }

    .ant-space-item {
        min-width: 200px;
    }

    .ant-statistic:last-of-type {
        margin-top: 20px;
    }
}


.pbm-filter {
    margin-top: 10px;
    padding: 10px 10px 2px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

    .ant-input, .ant-select {
        width: 165px;
    }

    .ant-space {
        flex-wrap: wrap;
    }

    .ant-space-item {
        margin-bottom: 8px;
    }
}

.pbm-table {
    margin-top: 10px;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

    .ant-table-column-sorters {
        padding: 5px 5px;
    }

    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 5px;
    }

}
