#root {
    height: 100%;
    position: relative;
}

/*body {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

@media screen and (orientation: portrait) {
    #root {
        position: absolute;
        width: 100vh;
        height: 100vw;
        top: 0;
        left: 100vw;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        transform-origin: 0% 0%;
    }
}

@media screen and (orientation: landscape) {
    #root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}*/


.page-frame {
    height: 100vh;
    background-color: #f0f2f5;


    &-content {
        padding: 10px;
        height: 100%;
        overflow: auto;
        position: relative;
        z-index: 1000;
    }
    .ant-menu-inline-collapsed {
        width: 70px;
    }
}


.ps-logo {
    height: 64px;
    display: flex;
    align-items: center;

    &-icon {
        width: 32px;
        height: 32px;
        margin-left: 19px;
        border-radius: 4px;
    }

    > h2 {
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        margin: 0 0 0 12px;
        white-space: nowrap;
        transition: all 300ms;
        transform-origin: left;
        transform: scale(1);
    }

    .ps-logo &-hide {
        transform: scale(0);
    }
}

.ps-menu {
    height: calc(100% - 64px);
    overflow: auto;
}

.page-footer {
    position: absolute;
    bottom: 10px;
    text-align: center;
    left: 50%;
}
