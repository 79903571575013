.third-login-content {
    display: flex;
    height: 50px;
    margin-top: 18px;
    margin-left: 5px;
    justify-content: space-around;
}

.third-login-img {
    width: 100%;
    height: 100%;
}

#my-login-button-target {
    width: 50px;
    .fp5nm8g {
        width: 100% !important;
        height: 50px !important;
        background-position: 15px 15px !important;
        background-size: 21px !important;
        .f8ario5 {
            opacity: 0;
        }
    }
    .fe90oc4 {
        width: 100% !important;
        height: 50px !important;
        background-position: 15px 15px !important;
        background-size: 21px !important;
    }
}

.my-login-button-hide {
    display: none;
}