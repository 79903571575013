.page-login {
    height: 100%;
    position: relative;
    background-image: linear-gradient(45deg, #00138b, #020c69 60%, #000330);
    min-width: 350px;
    min-height: 314px;

    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
        z-index: 900;
        background: #fff;
        width: 350px;
        height: 380px;
        //position: absolute;
        left: calc(50% - 175px);
        top: calc(50% - 161px);
        padding: 18px 25px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    }

    &-switch {
        text-align: center;

        .ant-radio-button-wrapper {
            padding: 0 5px;
        }
    }

    &-btn {
        margin-top: 10px;
        text-align: center;
    }

    &-logo {
        position: absolute;
        top: 16px;
        left: 16px;

        > img {
            width: 32px;
            height: 32px;
            border-radius: 4px;
        }

    }

    &-setting {
        position: absolute;
        top: 0;
        right: 0;
    }

    &-footer {
        position: absolute;
        bottom: 10px;
        text-align: center;
        width: 100%;
        color: #fff;
    }
}

.pl-item {
    margin-top: 10px;

    &-content {
        margin-top: 3px;
        display: flex;
    }

}

.pl-contcat {
    &-area {
        width: 126px;
    }

    &-phone {
        margin-left: 5px;
    }
}

.pl-vcode {
    &-image {
        margin-left: 10px;
        height: 35px;
        width: 102px;
        flex-shrink: 0;
    }
}

.pl-code {
    &-btn {
        margin-left: 10px
    }
}

.page-login-ext {
    position: relative;
}

.third-login-btn {
    position: absolute;
    z-index: 901;
    width: 100%;
    bottom: 16px;
}
