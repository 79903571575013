.page-header {
    padding: 0 16px;
    width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.ph-trigger {
    font-size: 20px;
}

.ph-user {
    &-name {
        margin-left: 5px;
    }
}

.ph-lang {
    position: relative;
    top: 2px;
}
