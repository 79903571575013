.page-broker-users {
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    overflow: auto;
}

.pbu-header {
    .ant-input, .ant-select {
        width: 165px;
    }

    .ant-space {
        flex-wrap: wrap;
    }

    .ant-space-item {
        margin-bottom: 8px;
    }
}

.pbu-table {
    margin-top: 5px;

    .ant-table-column-sorters {
        padding: 5px 5px;
    }

    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 5px;
    }

}
